<template>
  <v-card>
    <v-list-item>
      <v-list-item-avatar color="grey">
        <v-icon dark>mdi-domain</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        {{ client.name }}
        <v-list-item-subtitle>
          {{ client.address_one }}<br />
          {{ client.address_two }}<br />
          {{ client.city }} {{ client.state }} {{ client.zipcode }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <p>
        Main Contact: {{ mainContact }}<br />
        Users: {{ userCount }}<br />
        Projects: {{ projectCount }}<br />
        Reports: {{ reportCount }}
      </p>
    </v-card-text>
    <v-card-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-account-multiple</v-icon>
          </v-btn>
        </template>
        <span>{{ userCount }} Users</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="viewClientProjects">
            <v-icon>mdi-clipboard-list</v-icon>
          </v-btn>
        </template>
        <span>{{ projectCount }} Projects</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="viewClientReports">
            <v-icon>mdi-chart-bar</v-icon>
          </v-btn>
        </template>
        <span>{{ reportCount }} Reports</span>
      </v-tooltip>

      <v-spacer />

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="editClient">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>Edit</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="promptForDelete">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>Delete {{ client.name }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ClientCard",
  props: {
    client: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),
  computed: {
    reportCount: function() {
      return this.client.reports.length;
    },
    projectCount: function() {
      return this.client.projects.length;
    },
    userCount: function() {
      return this.client.users.length;
    },
    mainContact: function() {
      if (!this.client.main_contact) {
        return "None";
      } else {
        return this.client.main_contact.name;
      }
    }
  },
  methods: {
    loadForm(client) {
      this.$emit("loadClientForm", client);
    },
    viewClientReports() {
      this.$router.push({ path: `/reports/${this.client.id}` });
    },
    viewClientProjects() {
      this.$router.push({ path: `/projects/${this.client.id}` });
    },
    promptForDelete() {
      this.$emit("delete-clicked", this.client);
    },
    editClient() {
      this.$emit("edit-clicked", this.client);
    }
  }
};
</script>
