<template>
  <v-form ref="form">
    <v-card>
      <v-card-title>
        <span class="headline">Client Form</span>
      </v-card-title>
      <v-card-text>
        <v-progress-linear v-if="formLoading" indeterminate />
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.name"
                :label="$t('client_name')"
                :disabled="formLoading"
                required
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="formData.feedback_iframe_target"
                label="Feedback URL"
                :disabled="formLoading"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="formData.allowed_domains"
                label="Allowed Domains (Comma separated)"
                :disabled="formLoading"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ApolloQuery :query="require('@/graphql/user/userDropdown.gql')">
                <template v-slot="{ result: { loading, error, data } }">
                  <v-autocomplete
                    v-if="data"
                    :items="data.allUsers"
                    :disabled="formLoading"
                    :loading="loading"
                    item-text="name"
                    item-value="id"
                    v-model="main_contact_id"
                    label="Main Contact"
                    required
                  />
                </template>
              </ApolloQuery>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red darken-1"
          text
          @click="$emit('client-form-closed')"
          :disabled="formLoading"
          >{{ $t("close") }}</v-btn
        >
        <v-btn
          v-if="formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          :loading="formLoading"
          @click="updateClient()"
          >{{ $t("save") }}</v-btn
        >
        <v-btn
          v-if="!formData.id"
          color="primary darken-1"
          text
          :disabled="formLoading"
          :loading="formLoading"
          @click="createClient()"
          >{{ $t("create") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
export default {
  name: "ClientForm",
  props: {
    client: {
      type: Object,
      default: () => ({
        id: "",
        name: "",
        users: [],
        allowed_domains: "",
        feedback_iframe_target: "",
        main_contact: {}
      })
    }
  },
  data: () => ({
    isValid: true,
    mutation: "",
    formLoading: false,
    defaultForm: {
      id: "",
      name: "",
      users: [],
      feedback_iframe_target: "",
      allowed_domains: "",
      main_contact: {
        id: "",
        name: ""
      }
    },
    formData: {}
  }),
  created() {
    this.formData = { ...this.defaultForm, ...this.client };
  },
  computed: {
    main_contact_id: {
      // this feels wrong, but it works...
      // getter
      get: function() {
        if (!this.client.main_contact) {
          return "";
        } else {
          if (this.client.main_contact.id !== "") {
            return this.client.main_contact.id;
          } else {
            return "";
          }
        }
      },
      // setter
      set: function(newValue) {
        this.formData.main_contact = { id: newValue };
      }
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.isValid = true;
        this.saveForm();
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    updateClient() {
      this.mutation = require("@/graphql/client/updateClient.gql");
      this.saveForm();
    },
    createClient() {
      this.mutation = require("@/graphql/client/createClient.gql");
      this.saveForm();
    },
    saveForm() {
      this.formLoading = true;
      const variables = {
        id: this.formData.id,
        name: this.formData.name,
        feedback_iframe_target: this.formData.feedback_iframe_target,
        allowed_domains: this.formData.allowed_domains,
        main_contact: this.formData.main_contact.id
      };
      console.log(variables);
      this.$apollo
        .mutate({
          // Query
          mutation: this.mutation,
          // Parameters
          variables: {
            id: this.formData.id,
            name: this.formData.name,
            feedback_iframe_target: this.formData.feedback_iframe_target,
            allowed_domains: this.formData.allowed_domains,
            main_contact: this.formData.main_contact.id
          }
        })
        .then(data => {
          // report success
          this.$emit("client-form-saved");
          this.$emit("client-form-closed", data);
        })
        .catch(error => {
          // Error
          console.error(error);
          this.$store.dispatch("responseMessage", {
            type: "error",
            title: "Error",
            text:
              "Error saving form.  Please make sure all fields are completed."
          });
          // update user....
          this.formLoading = false;
        });
      this.formLoading = false;
    }
  },
  watch: {
    client: {
      handler: function() {
        this.formData = { ...this.defaultForm, ...this.client };
      },
      deep: true
    }
  }
};
</script>
